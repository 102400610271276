/* eslint-disable heavy-eslint/stick-to-fsd-hierarchy */
import { ThemeSwitcherButton } from "@widgets/themeSwitcherButton/ThemeSwitcherButton";
import { useGeneral } from "@entities/general";
import { Bars3Icon } from "@heroicons/react/24/solid";

export const OpenSideMenu = () => {
  const setShowSideMenu = useGeneral((state) => state.setShowSideMenu);

  return (
    <div className="flex gap-2">
      <button
        aria-label="open side menu"
        onClick={() => {
          setShowSideMenu(true);
        }}
      >
        <Bars3Icon className="w-10 text-black dark:text-dark-text" />
      </button>
      <ThemeSwitcherButton className="block lg:hidden" />
    </div>
  );
};
