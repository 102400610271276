import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";

type OpenMainPageProps = {
  className?: string;
};

export const OpenMainPage: React.FC<OpenMainPageProps> = ({ className }) => {
  const router = useRouter();

  return (
    <button className={className} title="Back to main page" onClick={() => router.push("/app")}>
      <ArrowLeftCircleIcon width={40} height={40} className="dark:text-dark-text" />
    </button>
  );
};
