import clsx from "clsx";

import { WideArrowIcon } from "@shared/icons";

import { useGeneral } from "@entities/general";

export const SwitchMainViews = () => {
  const toShow = useGeneral((state) => state.toShow);
  const setToShow = useGeneral((state) => state.setToShow);

  const onClickSwitchMainViews = () => {
    if (toShow === "chat") {
      setToShow("optimizer");
    } else {
      setToShow("chat");
    }
  };

  return (
    <div
      className={clsx(
        "relative flex items-center transition ease-in-out duration-300 justify-center rounded-lg w-[69px] py-[4px] px-[10px] lg:hidden cursor-pointer",
        toShow === "optimizer" ? "bg-primary dark:bg-dark-primary" : "bg-black dark:bg-dark-secondary"
      )}
      onClick={onClickSwitchMainViews}
    >
      <div className="text-white">
        <p className="flex items-center justify-between text-xs font-semibold">
          TO <WideArrowIcon />
        </p>
        <p className="text-xs font-semibold w-[49px]">{toShow === "optimizer" ? "AI CHAT" : "LINEUPS"}</p>
      </div>
    </div>
  );
};
