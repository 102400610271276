"use client";

import { useRouter, usePathname } from "next/navigation";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

import { DfsProIcon } from "@shared/icons";
import { useIsPublic } from "@shared/hooks";
import { MaxWidthWrapper } from "@shared/ui";

import { useUserStore } from "@entities/user";

import { SwitchMainViews } from "@features/switchMainViews";
import { OpenSideMenu } from "@features/openSideMenu";
import { OpenMainPage } from "@features/openMainPage";
import { useGeneral } from "@entities/general";
import { ThemeSwitcherButton } from "../../themeSwitcherButton/ThemeSwitcherButton";

export const Header = () => {
  const userSignedIn = useUserStore((state) => state.signedIn);
  const isAppLoading = useGeneral((state) => state.requestLoading.status && !state.requestLoading.message);

  const router = useRouter();
  const pathname = usePathname();
  const pageIsPublic = useIsPublic();

  const isMainPage = pathname === "/app";
  const isLandingPage = pathname === "/" || pathname === "/start-free-trial";

  if (isLandingPage) return null;

  if (!userSignedIn && !pageIsPublic) return null;

  return (
    <header className="relative border-b-[1px] border-gray-200 bg-white px-2 overflow-hidden dark:bg-gray-750 dark:border-0">
      <MaxWidthWrapper>
        <div className="flex h-[54px] items-center justify-between mx-2">
          <div className="flex items-center gap-2">
            {userSignedIn && <OpenSideMenu />}

            {!isMainPage ? <OpenMainPage className="hidden lg:block" /> : null}
          </div>

          <div
            className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center gap-x-2 cursor-pointer"
            title="Back to main page"
            onClick={() => router.push("/app")}
          >
            <DfsProIcon className="w-8 dark:fill-dark-text" />
            <h2 className="non-selectable text-[24px] dark:text-white">
              <span className=" font-extralight">dfs</span>Pro
            </h2>
          </div>

          {!isMainPage ? <OpenMainPage className="block lg:hidden" /> : <SwitchMainViews />}

          <div className="gap-2 hidden lg:flex">
            <ThemeSwitcherButton />
            <a
              aria-label="Click here to send us an email"
              href="mailto:help@dfspro.ai"
              title="Click here to send us an email"
            >
              <QuestionMarkCircleIcon className="h-8 dark:text-dark-text" />
            </a>
          </div>
        </div>
        {isAppLoading && (
          <div className="h-[2px] w-full absolute top-[3.2em] overflow-hidden bg-blue-500 animate-[loading-line_1s_linear_infinite]" />
        )}
      </MaxWidthWrapper>
    </header>
  );
};
