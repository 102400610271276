import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.4_next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@_23g2ryvzbm2wdwutisyl2ku7ra/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.4_next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@_23g2ryvzbm2wdwutisyl2ku7ra/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.4_next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@_23g2ryvzbm2wdwutisyl2ku7ra/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Slider"] */ "/app/node_modules/.pnpm/@nextui-org+slider@2.2.15_@nextui-org+system@2.2.5_@nextui-org+theme@2.2.9_tailwindcss@3.4.4__izr22pjck3xfugxlnbkj3kix74/node_modules/@nextui-org/slider/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Accordion/Accordion.js");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionContent"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Accordion/AccordionContent.js");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionPanel"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Accordion/AccordionPanel.js");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionTitle"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Accordion/AccordionTitle.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Banner/BannerCollapseButton.js");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Carousel/Carousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["DarkThemeToggle"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/DarkThemeToggle/DarkThemeToggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["Datepicker"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Datepicker/Datepicker.js");
;
import(/* webpackMode: "eager", webpackExports: ["Dropdown"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Dropdown/Dropdown.js");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownDivider"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Dropdown/DropdownDivider.js");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownHeader"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Dropdown/DropdownHeader.js");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownItem"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Dropdown/DropdownItem.js");
;
import(/* webpackMode: "eager", webpackExports: ["Floating"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Floating/Floating.js");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Modal/Modal.js");
;
import(/* webpackMode: "eager", webpackExports: ["ModalBody"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Modal/ModalBody.js");
;
import(/* webpackMode: "eager", webpackExports: ["ModalFooter"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Modal/ModalFooter.js");
;
import(/* webpackMode: "eager", webpackExports: ["ModalHeader"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Modal/ModalHeader.js");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Navbar/Navbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarBrand"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarBrand.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarCollapse"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarCollapse.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarLink"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarToggle"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarToggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["Popover"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Popover/Popover.js");
;
import(/* webpackMode: "eager", webpackExports: ["Rating"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Rating/Rating.js");
;
import(/* webpackMode: "eager", webpackExports: ["RatingStar"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Rating/RatingStar.js");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Sidebar/Sidebar.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarCollapse"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarCollapse.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarCTA"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarCTA.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarItem"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarItem.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarItemGroup"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarItemGroup.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarItems"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarItems.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarLogo"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarLogo.js");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Table/Table.js");
;
import(/* webpackMode: "eager", webpackExports: ["TableBody"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Table/TableBody.js");
;
import(/* webpackMode: "eager", webpackExports: ["TableCell"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Table/TableCell.js");
;
import(/* webpackMode: "eager", webpackExports: ["TableHead"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Table/TableHead.js");
;
import(/* webpackMode: "eager", webpackExports: ["TableHeadCell"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Table/TableHeadCell.js");
;
import(/* webpackMode: "eager", webpackExports: ["TableRow"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Table/TableRow.js");
;
import(/* webpackMode: "eager", webpackExports: ["TabItem"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Tabs/TabItem.js");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Tabs/Tabs.js");
;
import(/* webpackMode: "eager", webpackExports: ["Timeline"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Timeline/Timeline.js");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineBody"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineBody.js");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineContent"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineContent.js");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineItem"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineItem.js");
;
import(/* webpackMode: "eager", webpackExports: ["TimelinePoint"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Timeline/TimelinePoint.js");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineTime"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineTime.js");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineTitle"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineTitle.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toast"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Toast/Toast.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastToggle"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/components/Toast/ToastToggle.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/hooks/use-theme-mode.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeClientInit"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/theme-store/init/client.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeModeInit"] */ "/app/node_modules/.pnpm/flowbite-react@0.7.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_tailwindcss@3.4.4_ts-node@10_lzzygde7ultoczdfzekiuj4b3u/node_modules/flowbite-react/lib/esm/theme-store/init/mode.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"fallback\":[\"Helvetica\",\"ui-sans-serif\"]}],\"variableName\":\"poppinsFont\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/images/no-data.png");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/Modal/Modal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/NumericInput/NumericInput.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/Pagination/Pagination.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/Select/Select.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/Toaster/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/header/Header/Header.tsx");
