import { useEffect } from "react";
import { usePathname } from "next/navigation";

/**
 * Hook that returns whether the current page is public, i.e. does not require authentication
 */
export const useIsPublic = () => {
    const pathname = usePathname();

    return [
        '/privacy-policy',
        '/terms-of-service',
        '/3rd-party-providers',
        '/pro-tips',
        '/about',
        '/start-free-trial',
        '/',
    ].includes(pathname)
}

/**
 * Hook that calls the given callback when the escape key is pressed
 * @param callback function to call when the escape key is pressed
 */
export const useEscape = (callback: () => void) => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") callback();
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [callback]);
};
