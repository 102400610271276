"use client";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

export const ThemeSwitcherButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const [isDarkMode, setIsDarkMode] = useState(
    typeof window === "undefined"
      ? true
      : localStorage.getItem("darkMode") === null
        ? matchMedia("(prefers-color-scheme: dark)").matches
        : Number(localStorage.getItem("darkMode"))
  );

  useEffect(() => {
    if (isDarkMode) {
      document.getElementsByTagName("html")[0].classList.add("dark");
    } else {
      document.getElementsByTagName("html")[0].classList.remove("dark");
    }
  }, [isDarkMode]);

  const handleThemeClick = () => {
    if (isDarkMode) {
      document.getElementsByTagName("html")[0].classList.remove("dark");
      localStorage.setItem("darkMode", "0");
      setIsDarkMode(0);
    } else {
      document.getElementsByTagName("html")[0].classList.add("dark");
      localStorage.setItem("darkMode", "1");
      setIsDarkMode(1);
    }
  };

  return (
    <button className="block" onClick={handleThemeClick} title="Switch light or dark theme" {...props}>
      {isDarkMode ? <SunIcon className="h-8 dark:text-dark-text" /> : <MoonIcon className="h-8 dark:text-dark-text" />}
    </button>
  );
};
