import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type GeneralState = {
  toShow: "chat" | "optimizer";
  showSideMenu: boolean;
  showWizard: boolean;
  showShortcutsModal: boolean;
  savedLineups: Api.SavedLineups;
  requestLoading: {
    status: boolean;
    message?: string;
  };
  multiLineupStream: {
    open: boolean;
    messages: string[];
    running: boolean;
    error?: boolean;
  };
};

type GeneralAction = {
  setToShow: (toShow: GeneralState["toShow"]) => void;
  setShowSideMenu: (showSideMenu: boolean) => void;
  toggleSideMenu: () => void;
  setShowWizard: (showWizard: boolean) => void;
  setShowShortcutsModal: (showShortcutsModal: boolean) => void;
  toggleShortcutsModal: () => void;
  setRequestLoading: (requestLoading: GeneralState["requestLoading"]) => void;
  setMultiLineupStream: (multiLineupStream: GeneralState["multiLineupStream"]) => void;
  setSavedLineups: (savedLineups: GeneralState["savedLineups"]) => void;
};

export const useGeneral = create<GeneralState & GeneralAction>()(
  devtools(
    (set) => ({
      toShow: "optimizer",
      showSideMenu: false,
      showWizard: false,
      showShortcutsModal: false,
      requestLoading: {
        status: false,
      },
      savedLineups: [],
      loadingMessage: "This is a message",
      multiLineupStream: {
        open: false,
        messages: [],
        running: false,
      },

      setToShow: (toShow) => set({ toShow }),
      setShowSideMenu: (showSideMenu) => set({ showSideMenu }),
      toggleSideMenu: () => set((state) => ({ showSideMenu: !state.showSideMenu })),
      setShowWizard: (showWizard) => set({ showWizard }),
      setShowShortcutsModal: (showShortcutsModal) => set({ showShortcutsModal }),
      toggleShortcutsModal: () => set((state) => ({ showShortcutsModal: !state.showShortcutsModal })),
      setRequestLoading: (requestLoading) => set({ requestLoading }),
      setMultiLineupStream: (multiLineupStream) => set({ multiLineupStream }),
      setSavedLineups: (savedLineups) => set({ savedLineups }),
    }),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
