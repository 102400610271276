/* eslint-disable no-useless-escape */
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type UserStore = {
  loaded: boolean;
  signedIn: boolean;
  id?: string;
  userName?: string;
  userEmail?: string;
  subscription?: {
    type: null | "trial" | "premium" | "elite";
    consumables: {
      messages: null | number;
      lineups: null | number;
    };
  };
};

type UserAction = {
  setUser: (user: UserStore) => Promise<void>;
  refreshSubscriptionData: () => Promise<void>;
};

export const useUserStore = create<UserStore & UserAction>()(
  devtools(
    (set) => ({
      loaded: false,
      signedIn: false,

      setUser: async (user) => {
        set({
          loaded: true,
          id: user.id,
          signedIn: user.signedIn,
          userName: user.userName,
          userEmail: user.userEmail,
        });
      },

      refreshSubscriptionData: async () => {
        const authToken: FlipPayUser = JSON.parse(
          document.cookie.replace(/(?:(?:^|.*;\s*)fp.user\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        ).access_token;
        fetch(`/api/subscription`, {
          method: "POST",
          body: JSON.stringify({ token: authToken }),
        })
          .then(async (response) => {
            const { data } = await response.json();
            const subscriptionInfo = data;
            if (subscriptionInfo) {
              set({
                subscription: {
                  type: subscriptionInfo.subscriptionTier,
                  consumables: {
                    lineups: subscriptionInfo.lineupsTokenCount,
                    messages: subscriptionInfo.messagesTokenCount,
                  },
                },
              });
            }
          })
          .catch((error) => {
            console.error("Error in fetching subscription data:", error);
          });
      },
    }),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
